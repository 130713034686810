<!--
 * @Description: 
 * @Autor: zhangzhang
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-04 12:22:54
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar'
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '网站',
          list: [
            {
              name: '主网站设计',
              path: '/admin/website/webHome'
            }
          ]
        }
      ]
    }
  }
}
</script>
